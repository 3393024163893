<template>
  <CustomTable
      style="min-width:100%; max-width:100px;"
      :tableName="tableName"
      :showStatus="true"
      :list="list"
      :pageProp="page"
      :pages="pages"
      :filterStatus="filterStatus"
      :showDownloadBtn="false"
      :showPendingQtt="true"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForOccurences"
      @changeTable="changeTable"
  ></CustomTable>
</template>
<script>
import {defineComponent, onMounted, reactive} from "vue";
import CustomTable from "@/components/customTables/CustomTableOrdersOccurences.vue";
import ApiService from "@/core/services/ApiService";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Encomendas",
  components: {
    CustomTable,
  },
  data() {
    return {
      tableName: "Detalhe",
      page: 1,
      pages: 1,
      list: [],
      itemsPerPage: 15,
      orders: Array,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
      filterStatus: ["Rececionada", "Expedida", "Concluída"],
    };
  },
  computed: {},
  watch: {},
  methods: {

    /*
    * ----------------DATA MANIPULATION----------------
    */
    paginatorHandler(page) {
      this.page = page;
      this.loadOrdersPhc(page)
    },
    changeTable(table) {
      this.chosenTable = table
    },

    //filters by status, update pages, and list to show
    updateStatus(status) {
      this.status = status;
      this.paginatorHandler(1);
    },
    queryForOccurences(objValue) {
      this.searchInput = objValue.searchInput;
      this.startDate = objValue.startDate;
      this.endDate = objValue.endDate;

      this.paginatorHandler(1);
    },

    /*
    * ------------------API FETCHING------------------
    */

    loadOrdersPhc(page) {
      let queryString = this.getQueryString(page);


      ApiService.get("/orders", queryString)
          .then(({data}) => {
            this.pages = data.totalPages,
                this.list = data.data
          })
          .catch(({data}) => {
            console.log(data);
          });
    },

    /*
    * -------------------UTILS--------------------
    */

    getQueryString(page) {
      let queryString = `?pageNumber=${page}&pageSize=${this.itemsPerPage}&numCliente=${store.getters.currentUser.numcl}&NumSerie=1`;


      if (this.startDate) {
        queryString += `&startDate=${this.startDate}`
      }
      if (this.endDate) {
        queryString += `&endDate=${this.endDate}`
      }
      if (this.status) {
        queryString += `&estado=${this.status}`
      }
      if (this.searchInput) {
        queryString += `&NumDossier=${this.searchInput}`
      }

      return queryString;

    },

    filterByStatusAndDocNumber() {
      //this.setAllOccurences();
      if (!store.getters.getOrders.length) return;

      let ordersList = store.getters.getOrders.filter(
          (p) =>
              (p.documentStatus + "").includes(this.status) &&
              (p.documentNumber + "").includes(this.searchInput)
      );

      return ordersList;
    },


    /*
    * -------------------STYLES-------------------
    */
  },
  mounted() {
    setCurrentPageBreadcrumbs("Encomendas");
    //on first load we will load only from last year

    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toISOString().substring(0, 10);


    this.loadOrdersPhc(1);

  },
});
</script>


